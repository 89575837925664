var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-8" },
        [
          _c("QQButton", {
            attrs: {
              label: "Simpli da caricare",
              color: "blue-grey",
              icon: "mdi-clipboard-check-multiple-outline",
              size: "xl",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onSimpliDaCaricare()
              },
            },
          }),
          _vm.isSede
            ? _c("QQButton", {
                attrs: {
                  label: "Simpli da validare",
                  color: "blue-grey",
                  icon: "mdi-arrange-send-backward",
                  size: "xl",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onSimpliDaValidare()
                  },
                },
              })
            : _vm._e(),
          _c("QQButton", {
            attrs: {
              label: "Simpli Validati",
              color: "blue-grey",
              icon: "mdi-arrange-send-backward",
              size: "xl",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onSimpliValidati()
              },
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }